import React, { useEffect, useState } from "react";
import moment from "moment";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { Container } from "../../../core/layout/styels";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const MemberGroup = (props) => {
  //to update the page title
  useEffect(() => {
    document.title = `Member Group - Membership Mangement`;
  }, []);

  const [attributes] = useState([
    {
      type: "text",
      placeholder: "Group Name",
      name: "groupName",
      validation: "",
      default: "",
      label: "Group Name",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "area/select",
      placeholder: "Area",
      collection: "area",
      showItem: "areaName",
      name: "area",
      validation: "",
      default: "",
      tag: true,
      label: "Area",
      required: false,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "checkbox",
      placeholder: "Status",
      collection: "group",
      showItem: "status",
      name: "status",
      validation: "",
      default: "true",
      tag: true,
      label: "Status",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Paid Amount",
      name: "totalAmountPaid",
      validation: "",
      default: "",
      label: "Paid Amount",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [member] = useState([
    {
      type: "text",
      placeholder: "Name",
      name: "name",
      validation: "",
      default: "",
      label: "Name",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Address",
      name: "address",
      validation: "",
      default: "",
      label: "Address",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Mobile Number",
      name: "mobileNumber",
      validation: "",
      default: "",
      label: "Mobile Number",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Blood Group",
      name: "bloodGroup",
      validation: "",
      default: "",
      tag: true,
      label: "Blood Group",
      showItem: "",
      required: false,
      view: true,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "A+, A-, B+, B-, AB+, AB-, O+, O-",
    },
    {
      type: "text",
      placeholder: "Profession",
      name: "profession",
      validation: "",
      default: "",
      label: "Profession",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Qualification",
      name: "qualification",
      validation: "",
      default: "",
      label: "Qualification",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Area of Interest",
      name: "areaOfInterest",
      validation: "",
      default: "",
      label: "Area of Interest",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Birthulmal",
      name: "birthulmal",
      validation: "",
      default: "0",
      label: "Birthulmal",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    // {
    //   type: "select",
    //   placeholder: "Type",
    //   name: "type",
    //   validation: "",
    //   default: "Member",
    //   tag: true,
    //   label: "Type",
    //   showItem: "type",
    //   required: false,
    //   view: false,
    //   filter: false,
    //   add: true,
    //   update: false,
    //   apiType: "CSV",
    //   selectApi: "Member",
    // },
    {
      type: "date",
      placeholder: "",
      name: "dob",
      showItem: "dateOfBirth",
      collection: "subscriber",
      validation: "",
      minDate: moment().add(-70, "years").toDate(),
      default: moment().toDate(),
      tag: true,
      label: "DOB",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "member-status/select",
      placeholder: "Member Status",
      name: "memberStatus",
      showItem: "status",
      validation: "",
      default: "",
      tag: true,
      label: "Member Status",
      required: false,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "designation/select",
      placeholder: "Designation",
      name: "designation",
      showItem: "designation",
      validation: "",
      default: "",
      tag: true,
      label: "Designation",
      required: false,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    // {
    //   type: "select",
    //   apiType: "API",
    //   selectApi: "members-group/select",
    //   placeholder: "Group ID",
    //   name: "groupId",
    //   showItem: "groupName",
    //   validation: "",
    //   default: "",
    //   tag: true,
    //   label: "Group ID",
    //   required: false,
    //   view: true,
    //   add: true,
    //   update: true,
    //   filter: false,
    // },
  ]);

  const [associates] = useState([
    {
      type: "text",
      placeholder: "Name",
      name: "name",
      validation: "",
      default: "",
      label: "Name",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Address",
      name: "address",
      validation: "",
      default: "",
      label: "Address",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Mobile Number",
      name: "mobileNumber",
      validation: "",
      default: "",
      label: "Mobile Number",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Blood Group",
      name: "bloodGroup",
      validation: "",
      default: "",
      tag: true,
      label: "Blood Group",
      showItem: "",
      required: false,
      view: true,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "A+, A-, B+, B-, AB+, AB-, O+, O-",
    },
    {
      type: "text",
      placeholder: "Profession",
      name: "profession",
      validation: "",
      default: "",
      label: "Profession",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Qualification",
      name: "qualification",
      validation: "",
      default: "",
      label: "Qualification",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Area of Interest",
      name: "areaOfInterest",
      validation: "",
      default: "",
      label: "Area of Interest",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Birthulmal",
      name: "birthulmal",
      validation: "",
      default: "0",
      label: "Birthulmal",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    // {
    //   type: "select",
    //   placeholder: "Type",
    //   name: "type",
    //   validation: "",
    //   default: "Associates",
    //   tag: true,
    //   label: "Type",
    //   showItem: "type",
    //   required: false,
    //   view: false,
    //   filter: false,
    //   add: true,
    //   update: false,
    //   apiType: "CSV",
    //   selectApi: "Associates",
    // },
    {
      type: "date",
      placeholder: "",
      name: "dob",
      showItem: "dateOfBirth",
      collection: "subscriber",
      validation: "",
      minDate: moment().add(-70, "years").toDate(),
      default: moment().toDate(),
      tag: true,
      label: "DOB",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "designation/select",
      placeholder: "Designation",
      name: "designation",
      showItem: "designation",
      validation: "",
      default: "",
      tag: true,
      label: "Designation",
      required: false,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "member-status/select",
      placeholder: "Member Status",
      name: "memberStatus",
      showItem: "status",
      validation: "",
      default: "",
      tag: true,
      label: "Member Status",
      required: false,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    // {
    //   type: "checkbox",
    //   placeholder: "Member Status",
    //   name: "memberStatus",
    //   validation: "",
    //   default: "true",
    //   tag: true,
    //   label: "Member Status",
    //   required: true,
    //   view: true,
    //   add: true,
    //   update: true,
    // },
  ]);

  const [admin] = useState([
    {
      type: "text",
      placeholder: "Name",
      name: "name",
      validation: "",
      default: "",
      label: "Name",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Username",
      name: "username",
      validation: "",
      default: "",
      label: "Username",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "password",
      placeholder: "Password",
      name: "password",
      validation: "",
      default: "",
      label: "Password",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [actions] = useState([
    {
      element: "button",
      type: "subList",
      id: "associates",
      itemTitle: {
        name: "name",
        type: "text",
        collection: "",
      },
      // itemTitle: "username",
      title: "Associates",
      attributes: associates,
      // formMode: `double`,
      params: {
        api: `associates`,
        parentReference: "group",
        itemTitle: {
          name: "name",
          type: "text",
          collection: "",
        },
        shortName: "Associates",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
        formMode: `double`,
      },
    },
    {
      element: "button",
      type: "subList",
      id: "member",
      itemTitle: {
        name: "name",
        type: "text",
        collection: "",
      },
      // itemTitle: "username",
      title: "Member",
      attributes: member,
      formMode: `double`,
      displayColumn: "double",
      params: {
        api: `member`,
        parentReference: "group",
        itemTitle: {
          name: "name",
          type: "text",
          collection: "",
        },
        shortName: "Member",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
        formMode: `double`,
        // displayColumn: "double",
      },
    },
    {
      element: "button",
      type: "subList",
      id: "admin",
      itemTitle: {
        name: "name",
        type: "text",
        collection: "",
      },
      // itemTitle: "username",
      title: "Admin",
      attributes: admin,
      // formMode: `double`,
      params: {
        api: `admin`,
        parentReference: "group",
        itemTitle: {
          name: "name",
          type: "text",
          collection: "",
        },
        shortName: "Admin",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
        formMode: `double`,
      },
    },
  ]);

  return (
    <Container className="noshadow">
      <ListTable
        actions={actions}
        api={`members-group`}
        itemTitle={{ name: "groupName", type: "text", collection: "group" }}
        shortName={`Member Group`}
        formMode={`double`}
        // displayColumn="double"
        {...props}
        attributes={attributes}
      ></ListTable>
    </Container>
  );
};
// exporting the page with parent container layout..
export default Layout(MemberGroup);
