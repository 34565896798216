export const projectSettings = {
  title: process.env.title ?? "Diet Food Management System",
  shortTitle: process.env.shortTitle ?? "DFMS",
  description: process.env.description ?? "Diet Food Management System",
  privileges: {
    admin: "6459f25d7f6e9664fbd7486f",
    doctor: "6459f25d7f6e9664fbd7486f",
  },
  theme: {
    theme: "rgb(2 129 54)",
    themeLight:"#f7fffa",
    pageBackground: "white",
    background: "white",
    foreground: "#4f4f4f",
    border: "#d9d9d9",
    secBackground: "white",
    secForeground: "black",
    lightBackground: "White",
    lightForeground: "Black",
    lightBorder: "Black",
    lightSecBackground: "White",
    lightSecForeground: "black",
    foregroundInvert: "white",
    backgroundInvert: "black;",
    borderThinkness: "0px",
    themeBackground: "#FFFFFF",
    themeForeground: "white",
    disabledBackground: "rgba(156, 156, 156, 0.41)",
    disabledForeground: "white",
  },
};
